@font-face {
  font-family: 'object sans';
  font-style: normal;
  font-weight: 400;
  src: local("object sans"), local("ObjectSans-Regular"), url("../fonts/PPObjectSans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: 'object sans';
  font-style: normal;
  font-weight: 700;
  src: local("object sans"), local("ObjectSans-Bold"), url("../fonts/PPObjectSans-Bold.woff2") format("woff2");
}

@font-face {
  font-family: 'bandwidth';
  font-style: normal;
  font-weight: 700;
  src: local("bandwidth"), local("BandwidthDisplay"), url("../fonts/BandwidthDisplay_Rg.woff2") format("woff2");
}


/* .rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
} */

.tracker-start {
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
  border-left: 1px solid;
}

.tracker-end {
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  border-right: 1px solid;
}

.container {
  --link-color: #fff;
}

header {
  z-index: 2 !important;
}

.modal-body button {
  display: none;
  visibility: hidden;
}

.sup, sup {
  top: -1.3em;
  font-size: 10px;
}

.form-control::-webkit-input-placeholder {
  color: rgba(216, 216, 216, 0.8);
  opacity: 1;
  font-weight: 400;
}
.form-control::-moz-placeholder {
  color: rgba(216, 216, 216, 0.8);
  opacity: 1;
  font-weight: 400;
}
.form-control:-ms-input-placeholder {
  color: rgba(216, 216, 216, 0.8);
  opacity: 1;
  font-weight: 400;
}
.form-control::-ms-input-placeholder {
  color: rgba(216, 216, 216, 0.8);
  opacity: 1;
  font-weight: 400;
}
.form-control::placeholder {
  color: rgba(216, 216, 216, 0.8);
  opacity: 1;
  font-weight: 400;
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.64rem;
  font-weight: 700;
  color:#525b75;
}

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 700;
  line-height: 1.49;
  color:#525b75;
}

.form-control {
  border-radius: 2rem;
  padding: 1.0rem 1rem;
  height: 36px;
  display: block;
  width: 100%;
  font-size: 1.0rem;
  font-weight: 400;
  line-height: 1.49;
  color: var(--phoenix-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  border: 1px solid rgb(178, 183, 196);
  -webkit-box-shadow: inset 0 1px 2px transparent;
  box-shadow: inset 0 1px 2px transparent;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}


.tx-sm {
  font-size: 13px;
}

.tx-xs {
  font-size: 12px;
}

.tx-xxs {
  font-size: 10px;
}


.tx-torch {
  color: #ff0037;
}

.nav-link {
  font-weight: 900;
  color: #fff;
}

/* .nav-link {
  font-weight: 900;
  color: #ff0037;
} */


.bd {
  font-family: 'bandwidth', 'Bandwidth Display', 'object sans', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 4rem;
  line-height: 4.5rem;
  text-transform: uppercase;
  font-weight: 400;
}

@media (max-width: 767px) {
  .bd {
    font-size: 2rem; /* Adjust the font size for mobile */
  }
}

.visa-card-img {
  max-width: 300px;
  width: 100%;  /* Ensures that the image is responsive but limited to max-width */
}

.smaller {
  font-size: 3rem;
}

.fbtn-torch {
  border: 1px solid #ff0037;
  background-color: #ff0037;
  color: #fff;
}

.fbtn-disabled {
  border: 1px solid $gray-500;
  background-color: $gray-300;
  color: #fff;
}

.fbtn {
  display: inline-block;
  /* width: 100%; */
  height: auto;
  padding: 14px 24px;
  border-radius: 25px;
  box-shadow: none;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 1.22;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: normal;
}

.fbtn:hover {
  color: #ff0037;
  text-decoration: none;
  background-color: #fff;
  border-color: #ff0037;
  transition: 0.2s;
  transition-behavior: normal;
  transition-delay: 0s;
  transition-property: all;
  transition-timing-function: ease;
}

.fbadge {
  border-radius: 25px;
}

@media (min-width: 1024px) {
  .fbtn {
    width: auto;
  }
}

.fbtn,
[role=button] {
  cursor: pointer;
}


a>span,
a>em {
  pointer-events: none;
}

.component .fbtn {
  text-transform: uppercase;
}

h3 {
  font-weight: 700;
  font-size: 2.625rem;
}

strong {
  font-weight: 700;
}

body {
  font-family: 'object sans', Tahoma, Geneva, Verdana, sans-serif;
}

.f-card {
  border-radius: 16px;
  margin-bottom: 8px;
  z-index: 1;
}

.bg-white {
  background-color: #fff;
}

.bg-torch {
  background-color: #ff0037;
}

.bg-midnight-express {
  background-color: #141928;
}

.bg-electric-blue {
  background-color: #96fff5;
}

.tx-midnight-express {
  color: #141928;
}

.tx-electric-blue {
  color: #96fff5;
}

.bg-zircon {
  background-color: #f3f4f4;
}

.bg-secondary-color {
  background-color: #141928;
}

.major-subhead {
  font-size: 36pt;
}

.lg-text-1 {
  font-size: 24pt;
}

.lg-text-2 {
  font-size: 28pt;
}

.sm-text-1 {
  font-size: 8pt;
}

.sm-text-2 {
  font-size: 12pt;
}

.divider-text {
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0.5px;
  margin: 15px 0;
}

.divider-text::before,
.divider-text::after {
  content: "";
  display: block;
  flex: 1;
  height: 1px;
  background-color: #e5e9f2;
}

.divider-text::before {
  margin-right: 10px;
}

.divider-text::after {
  margin-left: 10px;
}

/* .form-control {
  padding: 0.375rem 0.75rem 0.1rem;
} */

.steps {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: flex-start;
}

.step-item {
  display: inline-block;
  position: relative;
}

.step-item+.step-item {
  margin-left: 5px;
}

@media (min-width: 480px) {
  .step-item+.step-item {
    margin-left: 10px;
  }
}

@media (min-width: 768px) {
  .step-item+.step-item {
    margin-left: 36px;
  }
}

/* .step-item + .step-item::before {
    font-family: "Font Awesome 6 Free";
    content: "\f35a";
    font-size: 16px;
    font-weight: 400;
    color: #c0ccda;
    position: absolute;
    top: 50%;
    left: -26px;
    line-height: 0;
  } */

@media (min-width: 768px) {
  .step-item+.step-item::before {
    display: block;
  }
}

.step-item.active .step-link {
  color: #ff0037;
}

.step-item.active .step-number,
.step-item.active .step-icon {
  background-color: #ff0037;
  border-color: #ff0037;
  color: #fff;
}

.step-item.active ul li.complete a {
  color: #ff0037;
  text-decoration: line-through;
}

.step-item.active ul li.active a {
  color: #ff0037;
}

.step-item.active ul li.disabled a {
  color: #97a3b9;
}

.step-item.disabled .step-link {
  color: #c1c7ce;
  cursor: default;
}

.step-item.disabled .step-number,
.step-item.disabled .step-icon {
  border-color: #c1c7ce;
  color: #c1c7ce;
}

.step-item.disabled .step-desc {
  color: #c1c7ce;
}

.step-item.disabled ul a {
  color: #c1c7ce;
}

.step-item.complete .step-link {
  color: #0c8842;
}

.step-item.complete .step-number,
.step-item.complete .step-icon {
  background-color: #0c8842;
  border-color: #0c8842;
  color: #fff;
}

.step-item.complete ul li.complete a {
  color: #0c8842;
  text-decoration: line-through;
}

.step-item ul {
  position: relative;
  margin: 5px 0 -15px 12px;
  padding: 0;
  list-style: none;
  border-left: 1px solid rgba(72, 94, 144, 0.16);
  padding-left: 26px;
}

@media (min-width: 576px) {
  .step-item ul {
    margin-left: 16px;
  }
}

.step-item ul a {
  color: #8392a5;
  display: block;
}

.step-item:last-child ul {
  margin-bottom: 0;
}

.step-link {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #1b2e4b;
}

@media (min-width: 576px) {
  .step-link {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  .step-link {
    font-size: 14px;
  }
}

.step-link:hover,
.step-link:focus {
  color: #1b2e4b;
}

.step-link>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 12px;
}

.step-link>div span {
  display: block;
}

.step-link>div .step-title {
  margin-left: 0;
}

.step-number {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: "Font Awesome 6 Free", -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
  color: #8392a5;
  border: 2px solid #8392a5;
  border-radius: 100%;
}

@media (min-width: 768px) {
  .step-number {
    width: 32px;
    height: 32px;
  }
}

.step-icon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 2px solid #8392a5;
  color: #8392a5;
  border-radius: 100%;
}

.step-icon svg {
  width: 20px;
  height: 20px;
}

.step-title {
  margin-left: 5px;
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 375px) {
  .step-title {
    width: 60px;
  }
}

@media (min-width: 480px) {
  .step-title {
    width: 100px;
  }
}

@media (min-width: 576px) {
  .step-title {
    margin-left: 10px;
    width: auto;
    white-space: normal;
  }
}

@media (min-width: 768px) {
  .step-title {
    width: auto;
    overflow: visible;
    text-overflow: inherit;
  }
}

.step-desc {
  display: block;
  font-size: 11px;
  font-weight: 400;
  color: #8392a5;
}

.steps-sm .step-item+.step-item::before {
  font-size: 12px;
}

.steps-sm .step-link {
  font-size: 11px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.2px;
}

.steps-sm .step-number {
  width: 25px;
  height: 25px;
}

.steps-lg .step-link {
  font-size: 12px;
}

@media (min-width: 576px) {
  .steps-lg .step-link {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .steps-lg .step-link {
    font-size: 16px;
  }
}

.steps-lg .step-number {
  width: 32px;
  height: 32px;
}

@media (min-width: 576px) {
  .steps-lg .step-number {
    width: 38px;
    height: 38px;
  }
}

@media (min-width: 992px) {
  .steps-lg .step-number {
    width: 42px;
    height: 42px;
  }
}

.steps-lg .step-title {
  display: flex;
  flex-direction: column;
}

.steps-lg .step-title span {
  display: block;
  line-height: 1.2;
}

.steps-justified {
  display: flex;
  align-items: center;
}

.steps-justified .step-item {
  flex: 1;
}

.steps-justified .step-item+.step-item::before {
  display: none;
}

.steps-justified .step-link {
  width: 100%;
}

.steps-tab .step-item+.step-item {
  margin-left: 1px;
}

.steps-tab .step-item+.step-item::before {
  display: none;
}

.steps-tab .step-item.active .step-link {
  background-color: #a804b2;
  color: #fff;
}

.steps-tab .step-item.active .step-number {
  border-color: #fff;
}

.steps-tab .step-item.complete .step-link {
  background-color: #0c8842;
  color: #fff;
}

.steps-tab .step-item.complete .step-number {
  border-color: #fff;
}

.steps-tab .step-item.disabled .step-number {
  border-color: #e3e7ed;
}

.steps-tab .step-link {
  background-color: #eff2f5;
  padding: 5px 7px;
}

@media (min-width: 576px) {
  .steps-tab .step-link {
    padding: 8px 15px;
  }
}

.steps-vertical {
  display: flex;
  flex-direction: column;
}

.steps-vertical .step-item+.step-item {
  margin-left: 0;
  margin-top: 25px;
}

.steps-vertical .step-item+.step-item::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 12px;
  height: 15px;
  width: 1px;
  background-color: #cdd4e0;
  display: block;
}

@media (min-width: 576px) {
  .steps-vertical .step-item+.step-item::before {
    left: 16px;
  }
}

.steps-vertical .step-title {
  width: auto;
  white-space: normal;
}

.steps-vertical.steps-tab .step-item+.step-item {
  margin-top: 1px;
}

.steps-vertical.steps-sm .step-item+.step-item::before {
  left: 12px;
}


.tracker {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: flex-start;
}

.tracker-item {
  display: inline-block;
  position: relative;
}

.tracker-item+.tracker-item {
  padding: 7px 25px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
}

@media (min-width: 480px) {
  .tracker-item+.tracker-item {
    /* margin-left: 10px; */
  }
}

@media (min-width: 768px) {
  .tracker-item+.tracker-item {
    /* margin-left: 36px; */
  }
}

@media (min-width: 768px) {
  .tracker-item+.tracker-item::before {
    display: block;
  }
}

.tracker-item.active {
  background-color: #ff0037;
  color: #fff;
}

.tracker-item.active .tracker-link {
  color: #fff
}

.tracker-item.active .tracker-number,
.tracker-item.active .tracker-icon {
  background-color: #ff0037;
  border-color: #ff0037;
  color: #fff;
}

.tracker-item.active ul li.complete a {
  color: #ff0037;
  text-decoration: line-through;
}

.tracker-item.active ul li.active a {
  color: #ff0037;
}

.tracker-item.active ul li.disabled a {
  color: #97a3b9;
}

.tracker-item.disabled .tracker-link {
  color: #c1c7ce;
  cursor: default;
}

.tracker-item.disabled .tracker-number,
.tracker-item.disabled .tracker-icon {
  border-color: #c1c7ce;
  color: #c1c7ce;
}

.tracker-item.disabled .tracker-desc {
  color: #c1c7ce;
}

.tracker-item.disabled ul a {
  color: #c1c7ce;
}

.tracker-item.complete .tracker-link {
  color: #0c8842;
}

.tracker-item.complete .tracker-number,
.tracker-item.complete .tracker-icon {
  background-color: #0c8842;
  border-color: #0c8842;
  color: #fff;
}

.tracker-item.complete ul li.complete a {
  color: #0c8842;
  text-decoration: line-through;
}

/* .tracker-item ul {
  position: relative;
  margin: 5px 0 -15px 12px;
  padding: 0;
  list-style: none;
  padding-left: 26px;
} */

@media (min-width: 576px) {
  .tracker-item ul {
    /* margin-left: 16px; */
  }
}

.tracker-item ul a {
  color: #8392a5;
  display: block;
}

.tracker-item:last-child ul {
  margin-bottom: 0;
}

ul.tracker li:first-child {
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 7px 25px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

ul.tracker li:last-child {
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 7px 25px;
}

.tracker-link {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #cecece;
}

@media (min-width: 576px) {
  .tracker-link {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  .tracker-link {
    font-size: 14px;
  }
}

/* .tracker-link:hover,
.tracker-link:focus {
  color: #1b2e4b;
} */

.tracker-link>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 12px;
}

.tracker-link>div span {
  display: block;
}

.tracker-link>div .tracker-title {
  margin-left: 0;
}

.tracker-number {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: "Font Awesome 6 Free", -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
  color: #8392a5;
  border: 2px solid #8392a5;
  border-radius: 100%;
}

@media (min-width: 768px) {
  .tracker-number {
    width: 32px;
    height: 32px;
  }
}

.tracker-icon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 2px solid #8392a5;
  color: #8392a5;
  border-radius: 100%;
}

.tracker-icon svg {
  width: 20px;
  height: 20px;
}

.tracker-title {
  /* margin-left: 5px; */
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 375px) {
  .tracker-title {
    width: 60px;
  }
}

@media (min-width: 480px) {
  .tracker-title {
    width: 100px;
  }
}

@media (min-width: 576px) {
  .tracker-title {
    /* margin-left: 10px; */
    width: auto;
    white-space: normal;
  }
}

@media (min-width: 768px) {
  .tracker-title {
    width: auto;
    overflow: visible;
    text-overflow: inherit;
  }
}

.tracker-desc {
  display: block;
  font-size: 11px;
  font-weight: 400;
  color: #8392a5;
}

.tracker-sm .tracker-item+.tracker-item::before {
  font-size: 12px;
}

.tracker-sm .tracker-link {
  font-size: 11px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.2px;
}

.tracker-sm .tracker-number {
  width: 25px;
  height: 25px;
}

.tracker-lg .tracker-link {
  font-size: 12px;
}

@media (min-width: 576px) {
  .tracker-lg .tracker-link {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .tracker-lg .tracker-link {
    font-size: 16px;
  }
}

.tracker-lg .tracker-number {
  width: 32px;
  height: 32px;
}

@media (min-width: 576px) {
  .tracker-lg .tracker-number {
    width: 38px;
    height: 38px;
  }
}

@media (min-width: 992px) {
  .tracker-lg .tracker-number {
    width: 42px;
    height: 42px;
  }
}

.tracker-lg .tracker-title {
  display: flex;
  flex-direction: column;
}

.tracker-lg .tracker-title span {
  display: block;
  line-height: 1.2;
}

.tracker-justified {
  display: flex;
  align-items: center;
}

.tracker-justified .tracker-item {
  flex: 1;
}

.tracker-justified .tracker-item+.tracker-item::before {
  display: none;
}

.tracker-justified .tracker-link {
  width: 100%;
}


.status {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: flex-start;
  color: #cecece;
}

@media (min-width: 768px) {
  .status-item+.status-item::before {
    display: block;
  }
}

.status-item {
  display: inline-block;
  position: relative;
  display: flex;
  align-items: center;
  color: #cecece;
}

.status-item+.status-item {
  padding: 7px 25px;
}

ul.status li:first-child {
  padding: 7px 35px;
}

.status-item {
  /* margin-left: 5px; */
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 375px) {
  .status-item {
    width: 60px;
  }
}

@media (min-width: 480px) {
  .status-item {
    width: 100px;
  }
}

@media (min-width: 576px) {
  .status-item {
    /* margin-left: 10px; */
    width: auto;
    white-space: normal;
  }
}

@media (min-width: 768px) {
  .status-item {
    width: auto;
    overflow: visible;
    text-overflow: inherit;
  }
}
