
$color-brand-01:                  #3874ff;
$color-brand-02:                  #530493;
$color-brand-03:                  #1ce1ac;

$color-banner-01:                 #ff0037;

// UI Colors
$color-ui-01:                     #f5f6fa;
$color-ui-02:                     #e5e9f2;
$color-ui-03:                     #c0ccda;
$color-ui-04:                     #8392a5;

// Inverse Colors
$color-inverse-01:                #001737;
$color-inverse-02:                #1b2e4b;

// Text Colors
$color-text-01:                   $color-inverse-01;
$color-text-02:                   $color-inverse-02;
$color-text-03:                   #8392a5;
$color-text-04:                   #c0ccda;

$link-color:                      #ff0037;
$height-base:                     38px;
$height-header:                   60px;
$height-header-mobile:            55px;
$height-footer:                   46px;

$link-decoration: none !default;
$link-hover-decoration: underline !default;
$link-hover-color: $primary-text-emphasis !default;
